import {
  Button,
  ButtonAppearance,
  ContentBox,
  ContentBoxHead,
  LayerPortal,
  ResponsiveTableWrapper,
  Text,
  TextSize,
} from '@yarmill/components';
import { useLayer, useRootStore } from '@yarmill/utils';
import { observer } from 'mobx-react-lite';
import { Fragment, useEffect, useState } from 'react';
import { FormattedMessage } from 'react-intl';
import { HeartRateZoneForm } from './heart-rate-zone-form';
import { HeartRateZonesTable } from './heart-rate-zones-table';
import { HeartRateZonesSetStore } from './mobx/heart-rate-zones-set-store';
import { UserId } from '@yarmill/types';

interface HeartRateZonesProps {
  readonly userId: UserId;
}

export const HeartRateZones = observer(function HeartRateZones({
  userId,
}: HeartRateZonesProps): JSX.Element {
  const layer = useLayer('full-screen');
  const rootStore = useRootStore();
  const heartRateZonesManager = rootStore.heartRateZonesManagerStore;
  const [formItem, setFormItem] = useState<HeartRateZonesSetStore | null>(null);

  useEffect(() => {
    if (heartRateZonesManager.heartRateZonesSets.length === 0) {
      void heartRateZonesManager.loadHeartRateZones(userId);
    }
    if (heartRateZonesManager.availableSports.length === 0) {
      void heartRateZonesManager.loadAvailableSports();
    }

    return () => {
      heartRateZonesManager.clearZones();
    };
  }, [heartRateZonesManager, userId]);

  return (
    <>
      <ContentBox>
        <ContentBoxHead>
          <Text size={TextSize.s16} medium>
            <FormattedMessage id="settings.profile.heartRateZones" />
          </Text>
          <Button
            appearance={ButtonAppearance.Primary}
            square
            noShadow
            onClick={() => {
              if (!layer.isOpened) {
                const zoneSet = new HeartRateZonesSetStore(rootStore);
                zoneSet.createDefaultZones();
                setFormItem(zoneSet);
                layer.open();
              }
            }}
          >
            <strong>+</strong>
          </Button>
        </ContentBoxHead>
        <ResponsiveTableWrapper>
          <HeartRateZonesTable
            userId={userId}
            setFormItem={zoneSet => {
              if (!layer.isOpened) {
                setFormItem(zoneSet);
                layer.open();
              }
            }}
          />
        </ResponsiveTableWrapper>
      </ContentBox>
      <LayerPortal
        layerHandle={layer}
        getContent={layer =>
          formItem ? (
            <HeartRateZoneForm
              layer={layer}
              heartRateZonesSet={formItem}
              userId={userId}
            />
          ) : (
            <Fragment />
          )
        }
      />
    </>
  );
});

import { ThemeDefinition } from './theme-definition';

export const main: ThemeDefinition = {
  name: 'main',
  borderRadius: {
    x2: '16px',
    x15: '12px',
    x1: '8px',
    x075: '6px',
    x05: '4px',
    x025: '2px',
  },
  boxShadow: {
    bs1: '0 1px 2px 0 rgba(0, 0, 0, 0.06)',
    bs2: '0px 4px 8px 0px rgba(34, 35, 50, 0.16)',
    bs3: '0 0 0 1px #2161dc',
    bs4: '0px 4px 128px 0px rgba(0, 0, 0, 0.24), 0px 2px 8px 0px rgba(0, 0, 0, 0.08)',
  },
  color: {
    black: '#000000',
    blackHover: '#1B1C21',
    text: '#222332',
    white: '#FFFFFF',
    neutral: '#718BAA',
    neutral_8: '#F4F6F9',
    neutral_24: '#DDE3EB',
    neutral_40: '#C6D1DD',
    neutralPlate: '#FBFCFD',
    neutralPlateDark: '#EDF1F5',
    neutralDark: '#465567',
    navyDark: '#0084D0',
    navy: '#0094E8',
    navy_8: '#EBF7FE',
    navy_24: '#C2E6FA',
    navy_40: '#99D4F6',
    redDark: '#DA1617',
    red: '#E63136',
    red_8: '#FDEFEF',
    red_24: '#F9CECF',
    red_40: '#F5ADAF',
    greenDark: '#42A139',
    green: '#68C460',
    green_8: '#F3FBF3',
    green_24: '#DBF1D9',
    green_40: '#C3E7BF',
    tangerineDark: '#EB3C32',
    tangerine: '#FF574D',
    tangerine_8: '#FFF2F1',
    tangerine_24: '#FFD7D4',
    tangerine_40: '#FFBCB8',
    orangineDark: '#EA4905',
    orangine: '#FC4C02',
    orangine_8: '#FFF1EB',
    orangine_24: '#FED4C2',
    orangine_40: '#FEB79A',
    sand: '#FFA900',
    sandDark: '#EAA318',
    sand_8: '#FFF9EB',
    sand_24: '#FFEBC2',
    sand_40: '#FFDD99',
    blueDark: '#40ACCE',
    blue: '#5BC3E4',
    blue_8: '#F2FBFD',
    blue_24: '#D8F1F9',
    blue_40: '#BDE7F4',
    violetDark: '#7629D9',
    violet: '#8B30FF',
    violet_8: '#F6EFFF',
    violet_24: '#E3CEFF',
    violet_40: '#D1ACFF',
    pink: '#EB5C7A',
    pinkDark: '#DC5470',
    pink_8: '#FEF2F5',
    pink_24: '#FAD8DF',
    pink_40: '#F7BECA',
  },
  size: {
    x0125: '1px',
    x025: '2px',
    x05: '4px',
    x075: '6px',
    x1: '8px',
    x125: '10px',
    x15: '12px',
    x2: '16px',
    x25: '20px',
    x3: '24px',
    x35: '28px',
    x4: '32px',
    x45: '36px',
    x5: '40px',
    x55: '45px',
    x6: '48px',
    x7: '56px',
    x9: '72px',
  },
  text: {
    font: {
      default: 'Geist, sans-serif',
      mono: 'GeistMono, Roboto Mono, sans-serif',
      emoji: 'Apple Color Emoji, Noto Color Emoji, Android Emoji',
    },
    appearance: {
      h32: {
        fontSize: '32px',
        fontWeight: '700',
        lineHeight: '40px',
      },
      h28: {
        fontSize: '28px',
        fontWeight: '700',
        lineHeight: '36px',
      },
      text16: {
        fontSize: '16px',
        fontWeight: '400',
        lineHeight: '24px',
      },
      h16: {
        fontSize: '16px',
        fontWeight: '700',
        lineHeight: '24px',
      },
      nav16semi: {
        fontSize: '16px',
        fontWeight: '400',
        lineHeight: '24px',
      },
      nav16strong: {
        fontSize: '16px',
        fontWeight: '700',
        lineHeight: '24px',
      },
      text15strong: {
        fontSize: '15px',
        fontWeight: '700',
        lineHeight: '24px',
      },
      text15: {
        fontSize: '15px',
        fontWeight: '400',
        lineHeight: '24px',
      },
      task13strong: {
        fontSize: '13px',
        fontWeight: '700',
        lineHeight: '16px',
      },
      task13: {
        fontSize: '13px',
        fontWeight: '600',
        lineHeight: '16px',
      },
      text12strong: {
        fontSize: '12px',
        fontWeight: '700',
        lineHeight: '14px',
      },
      text12: {
        fontSize: '12px',
        fontWeight: '500',
        lineHeight: '14px',
      },
      button10: {
        fontSize: '10px',
        fontWeight: '700',
        lineHeight: '12px',
      },
      label10strong: {
        fontSize: '10px',
        fontWeight: '700',
        lineHeight: '12px',
      },
      label10: {
        fontSize: '10px',
        fontWeight: '500',
        lineHeight: '12px',
      },
      text8strong: {
        fontSize: '8px',
        fontWeight: '700',
        lineHeight: '12px',
      },
    },
  },
};

import { ROUTE_DATE_FORMAT, WEEK_SEARCH_PARAM } from '@yarmill/const';
import {
  generateUrl,
  getMaxBookDate,
  getMinBookDate,
  getWeekEnd,
  getWeekStart,
  useCurrentWeek,
  useHistory,
  useSeasonsStore,
} from '@yarmill/utils';
import moment from 'moment';
import { useCallback, useMemo, useState } from 'react';
import { FormattedDate, FormattedMessage } from 'react-intl';
import styled from 'styled-components';
import { Button, ButtonAppearance } from './button';
import { Datepicker } from './datepicker';
import { ExternalIcon } from './external-icon';
import { Icon, IconSize } from './icon';
import { Text, TextSize } from './text';
import {
  autoUpdate,
  flip,
  FloatingPortal,
  offset,
  shift,
  useClick,
  useDismiss,
  useFloating,
  useInteractions,
} from '@floating-ui/react';

const StyledWeekSelector = styled.div`
  display: flex;
  justify-content: space-between;
  flex-wrap: nowrap;
  align-items: center;
  font-size: 14px;
  border-radius: 4px;
  color: #4a4a4a;
`;

const StyledDropdownButton = styled.div`
  margin: 0 8px;
  position: relative;
  display: none;

  @media (min-width: 576px) {
    display: block;
  }
`;

const StyledWeekSelectorButton = styled(Button)`
  display: inline-grid;
  border-radius: 4px;
  color: #4a4a4a;
  grid-template-columns: auto auto;
  column-gap: 5px;
  align-items: center;
`;

const StyledDatepickerWrapper = styled.div``;

export interface WeekSelectorProps {
  onChange?: (week: string) => void;
}

export function WeekSelector(props: WeekSelectorProps): JSX.Element {
  const { onChange } = props;
  const [isLayerOpened, setIsLayerOpened] = useState(false);
  const week = useCurrentWeek();
  const seasons = useSeasonsStore().seasons;
  const weekStart = getWeekStart(week);
  const weekEnd = getWeekEnd(week);
  const history = useHistory();

  const { refs, floatingStyles, context } = useFloating({
    open: isLayerOpened,
    onOpenChange: setIsLayerOpened,
    placement: 'bottom',
    middleware: [
      flip({
        mainAxis: false,
      }),
      shift({
        padding: { left: 10, right: 10 },
      }),
      offset({
        mainAxis: 8,
      }),
    ],
    whileElementsMounted: autoUpdate,
  });

  const dismiss = useDismiss(context, {
    bubbles: true,
    ancestorScroll: false,
    outsidePress: true,
    outsidePressEvent: 'click',
  });

  const click = useClick(context);

  const { getReferenceProps, getFloatingProps } = useInteractions([
    dismiss,
    click,
  ]);

  const setWeek = useCallback(
    (w: moment.Moment): void => {
      const formattedWeek = w.format(ROUTE_DATE_FORMAT);
      const link = generateUrl({
        [WEEK_SEARCH_PARAM]: formattedWeek,
      });
      history.push(link);
      onChange?.(formattedWeek);
      setIsLayerOpened(false);
    },
    [history, onChange]
  );

  const goToNextWeek = useCallback((): void => {
    const nextWeek = moment(week).add(1, 'weeks');
    setWeek(nextWeek);
  }, [setWeek, week]);

  const goToPrevWeek = useCallback((): void => {
    const prevWeek = moment(week).subtract(1, 'weeks');
    setWeek(prevWeek);
  }, [setWeek, week]);

  const onDateChange = useCallback(
    (newDate: string): void => {
      if (newDate) {
        setWeek(moment(newDate));
        setIsLayerOpened(false);
      }
    },
    [setWeek]
  );

  const minBookingDate = useMemo(() => getMinBookDate(seasons), [seasons]);
  const maxBookingDate = useMemo(() => getMaxBookDate(seasons), [seasons]);

  return (
    <StyledWeekSelector data-cy="week-selector">
      <Button
        square
        noShadow
        onClick={goToPrevWeek}
        appearance={ButtonAppearance.Navigation}
        data-cy="prev-week"
      >
        <Icon size={IconSize.s20}>
          <ExternalIcon name="ChevronLeft" />
        </Icon>
      </Button>
      <StyledDropdownButton>
        <StyledWeekSelectorButton
          noShadow
          appearance={ButtonAppearance.Navigation}
          data-cy="toggle-calendar"
          ref={refs.setReference}
          {...getReferenceProps()}
        >
          <Icon>
            <ExternalIcon name="Calendar" />
          </Icon>
          <Text size={TextSize.s14}>
            <FormattedDate
              value={weekStart.toDate()}
              day="2-digit"
              month="2-digit"
            />
            &nbsp;-&nbsp;
            <FormattedDate
              value={weekEnd.toDate()}
              day="2-digit"
              month="2-digit"
              year="numeric"
            />
          </Text>
        </StyledWeekSelectorButton>
        {isLayerOpened && (
          <FloatingPortal>
            <StyledDatepickerWrapper
              style={floatingStyles}
              {...getFloatingProps()}
              ref={refs.setFloating}
            >
              <Datepicker
                value={week}
                onChange={onDateChange}
                selectRange="week"
                minBookingDate={minBookingDate}
                maxBookingDate={maxBookingDate}
                todayButtonLabel={
                  <FormattedMessage id="navbar.calendar.todayButton" />
                }
              />
            </StyledDatepickerWrapper>
          </FloatingPortal>
        )}
      </StyledDropdownButton>
      <Button
        noShadow
        square
        onClick={goToNextWeek}
        appearance={ButtonAppearance.Navigation}
        data-cy="next-week"
      >
        <Icon size={IconSize.s20}>
          <ExternalIcon name="ChevronRight" />
        </Icon>
      </Button>
    </StyledWeekSelector>
  );
}

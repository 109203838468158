import {
  Button,
  ButtonAppearance,
  CloseButton,
  ContentBox,
  ContentBoxHead,
  DescriptiveText,
  FormControlVariant,
  FullScreenLayerCloseButtonWrapper,
  LayerPortal,
  ProfileInfoItem,
  ProfileInfoLayout,
  Text,
  TextSize,
  toast,
} from '@yarmill/components';
import { UserStore } from '@yarmill/types';
import { useCurrentUserStore, useLayer } from '@yarmill/utils';
import { observer } from 'mobx-react-lite';
import { FormattedDate, FormattedMessage } from 'react-intl';
import { Services } from '../external-services/services';
import { trackChangePasswordClick } from '../google-analytics/utils';
import { HeartRateZones } from '../heart-rate-zones';
import { PolarZones } from '../heart-rate-zones/polar-zones';
import { useModule } from '../modules/hooks';
import { UserConsents } from '../users/api/change-email-preferences';
import { ChangePassword } from './change-password';
import { EmailPreferences } from './email-preferences';

export interface ProfileDetailProps {
  user: UserStore;
}

export const ProfileDetail = observer(function ProfileDetail(
  props: ProfileDetailProps
): JSX.Element {
  const { user } = props;
  const emailPreferencesLayer = useLayer('full-screen');
  const changePasswordLayer = useLayer('full-screen');
  const currentUser = useCurrentUserStore();
  const showExternalServices = useModule('externalServices');
  const onChangePasswordClick = (): void => {
    trackChangePasswordClick();
    if (!changePasswordLayer.isOpened) {
      changePasswordLayer.open();
    }
  };

  const changeEmailPreferences = async (
    userConsents: UserConsents
  ): Promise<void> => {
    const success = await currentUser.changeEmailPreferences(userConsents);

    if (success) {
      toast('toast.success.changeProfile', 'success');
      if (emailPreferencesLayer.isOpened) {
        emailPreferencesLayer.layer.close();
      }
    } else {
      toast('toast.error.changeProfile', 'error');
    }
  };

  const handleLayerOpen = (): void => {
    if (!emailPreferencesLayer.isOpened) {
      emailPreferencesLayer.open();
    }
  };

  return (
    <>
      <ContentBox>
        <ContentBoxHead>
          <Text size={TextSize.s16} medium>
            <FormattedMessage id="settings.profile.basicInfo" />
          </Text>
        </ContentBoxHead>

        <ProfileInfoLayout>
          <ProfileInfoItem
            label={<FormattedMessage id="settings.profile.lastName" />}
            value={user.internalUser.LastName}
          />
          <ProfileInfoItem
            label={<FormattedMessage id="settings.profile.firstName" />}
            value={user.internalUser.FirstName}
          />
          <ProfileInfoItem
            label={<FormattedMessage id="settings.profile.email" />}
            value={user.internalUser.Email}
          />
          <div />
          <ProfileInfoItem
            label={<FormattedMessage id="settings.profile.birthday" />}
            value={
              user.internalUser.Birthday ? (
                <FormattedDate
                  value={user.internalUser.Birthday}
                  day="2-digit"
                  month="2-digit"
                  year="numeric"
                />
              ) : (
                '-'
              )
            }
          />
          <ProfileInfoItem
            label={<FormattedMessage id="settings.profile.gender" />}
            value={
              user.internalUser.Gender ? (
                <FormattedMessage
                  id={`settings.profile.gender.${user.internalUser.Gender}`}
                />
              ) : (
                '-'
              )
            }
          />
          {currentUser.id === user.id && (
            <div>
              <Button
                appearance={ButtonAppearance.Primary}
                inverted
                noShadow
                onClick={onChangePasswordClick}
              >
                <FormattedMessage id="settings.profile.changePassword" />
              </Button>
            </div>
          )}
        </ProfileInfoLayout>
      </ContentBox>
      {currentUser.id === user.id && (
        <ContentBox>
          <ContentBoxHead>
            <Text medium>
              <FormattedMessage id="settings.profile.emailPreferences" />
            </Text>

            <Button
              appearance={ButtonAppearance.Primary}
              inverted
              noShadow
              onClick={handleLayerOpen}
              variant={FormControlVariant.default}
              data-cy="email-button-open"
            >
              <FormattedMessage id="settings.profile.emailPreferences.edit" />
            </Button>
          </ContentBoxHead>
          <DescriptiveText size={TextSize.s14} medium>
            <FormattedMessage id="settings.profile.emailPreferences.description" />
          </DescriptiveText>
        </ContentBox>
      )}
      {showExternalServices && user.id === currentUser.id && (
        <Services user={currentUser} />
      )}
      {currentUser.isAllowedTo('hrZonesManager') && user.isAthlete && (
        <>
          {user.id === currentUser.id &&
            currentUser.data?.ExternalServices.polar && <PolarZones />}
          <HeartRateZones userId={user.id} />
        </>
      )}
      <LayerPortal
        layerHandle={emailPreferencesLayer}
        getContent={layer => (
          <>
            <EmailPreferences
              closeLayer={layer.close}
              saveData={changeEmailPreferences}
              commercialConsent={Boolean(user.internalUser.CommercialConsent)}
              newsletterConsent={Boolean(user.internalUser.NewsletterConsent)}
            />
            <FullScreenLayerCloseButtonWrapper>
              <CloseButton onClick={layer.close} />
            </FullScreenLayerCloseButtonWrapper>
          </>
        )}
      />
      <LayerPortal
        layerHandle={changePasswordLayer}
        getContent={layer => <ChangePassword layer={layer} />}
      />
    </>
  );
});

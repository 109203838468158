import { observer } from 'mobx-react-lite';
import { Page } from '../page/page';
import { LazyModule } from './lazy-module';

export const Reporting = observer(function Reporting() {
  return (
    <Page>
      <LazyModule moduleCode="reporting" waitForRootStore />
    </Page>
  );
});

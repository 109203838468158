import { AsyncStatus } from '@yarmill/types';
import { useLocation, useRootStore } from '@yarmill/utils';
import { observer } from 'mobx-react-lite';
import { ReactElement } from 'react';
import { Redirect } from 'react-router-dom';
import { Announcement } from '../announcement/announcement';
import { useDefaultMainRoute } from '../app/hooks';
import { useIsLoggedIn } from '../auth/hooks';
import { FatalError } from '../fatal-error/fatal-error';

function InternalBaseRoute(): ReactElement {
  const rootStore = useRootStore();
  const initialConfigLoaded = rootStore.isReady;
  const configLoaded =
    initialConfigLoaded && rootStore.status === AsyncStatus.resolved;
  const isLoggedIn = useIsLoggedIn();
  const defaultRoutePath = useDefaultMainRoute();
  const location = useLocation();
  const currentUser = rootStore.currentUserStore;

  if (!isLoggedIn) {
    return (
      <Redirect
        to={{
          pathname: '/login',
          state: { from: location },
          search: window.location.search,
        }}
      />
    );
  }

  if (configLoaded) {
    return <Redirect to={defaultRoutePath} />;
  }

  if (
    rootStore.status === AsyncStatus.rejected &&
    currentUser.status !== AsyncStatus.pending
  ) {
    if (
      currentUser.status === AsyncStatus.resolved &&
      currentUser.acceptedLegalConsent !== null
    ) {
      return <Announcement />;
    } else {
      return <FatalError />;
    }
  }

  return <></>;
}

export const BaseRoute = observer(InternalBaseRoute);

import { ContentBox, PageMainContent } from '@yarmill/components';
import { useHelmetTitle, useUsersStore } from '@yarmill/utils';
import { observer } from 'mobx-react-lite';
import { useEffect, useRef } from 'react';
import { Helmet } from 'react-helmet';
import { useIntl } from 'react-intl';
import { Page } from '../page/page';
import { Sidebar } from '../settings/sidebar';
import { UsersHeader } from './header';
import { VirtualizedUsersList } from './virtualized-users-list';

export const Users = observer(function Users(): JSX.Element {
  const usersStore = useUsersStore();
  const pageMainContentRef = useRef<HTMLDivElement>(null);
  const usersHeaderRef = useRef<HTMLDivElement>(null);
  const sidebarRef = useRef<HTMLDivElement>(null);
  const intl = useIntl();

  const helmetTitle = useHelmetTitle([
    intl.formatMessage({
      id: `header.navigation.settings`,
    }),
  ]);

  useEffect(() => {
    void usersStore.loadDeactivatedUsers();
  }, [usersStore]);

  return (
    <>
      <Helmet>
        <title>{helmetTitle}</title>
      </Helmet>
      <Page>
        <Sidebar activeView="users" ref={sidebarRef} />
        <PageMainContent ref={pageMainContentRef}>
          <UsersHeader ref={usersHeaderRef} />
          <ContentBox>
            <VirtualizedUsersList
              pageMainContentRef={pageMainContentRef}
              usersHeaderRef={usersHeaderRef}
              sidebarRef={sidebarRef}
            />
          </ContentBox>
        </PageMainContent>
      </Page>
    </>
  );
});

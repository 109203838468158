import Select, { components, DropdownIndicatorProps } from 'react-select';
import { css, styled } from '../theme-provider';

import { useMergeRefs } from '@floating-ui/react';
import { ComponentType, Fragment, forwardRef, useMemo, useRef } from 'react';
import { lock, unlock } from 'tua-body-scroll-lock';
import { ExternalIcon } from '../external-icon';
import { FormControlVariant, SelectValueProps } from '../helpers';
import { Icon, IconSize } from '../icon';
import {
  StyledError,
  StyledHelp,
  StyledLabel,
  StyledOptionalMessage,
} from '../text-input';

export enum SelectAppearance {
  Primary,
  Secondary,
}

const CLASS_NAME_PREFIX = 'react_select';

const StyledSelect = styled(Select)<{
  noShadow?: boolean;
  appearance?: SelectAppearance;
  variant?: FormControlVariant;
  error?: string;
  longList?: boolean;
  noSeparator?: boolean;
}>`
  font-size: 13px;
  font-family: ${({ theme }) => theme.text.font.default};
  height: 36px;
  transition: all 0.2s ease-in-out;
  box-shadow: ${props =>
    props.noShadow ? 'none' : '0 0 5px rgba(0, 0, 0, 0.15)'};
  caret-color: transparent;
  border-radius: 4px;

  :hover,
  :focus {
    box-shadow: 0 0 30px rgba(74, 74, 74, 0.26);
    outline: none;
  }

  ${props =>
    props.appearance === SelectAppearance.Secondary &&
    css`
      .${CLASS_NAME_PREFIX}__control {
        border: 1px solid #4a90e2;
        background: transparent;

        :hover {
          border-color: #4a90e2;
        }
      }

      .${CLASS_NAME_PREFIX}__single-value, .${CLASS_NAME_PREFIX}__placeholder {
        color: #4a90e2 !important;
      }

      .${CLASS_NAME_PREFIX}__indicator {
        color: #4a90e2 !important;
      }

      :hover,
      :focus {
        box-shadow: 0 0 5px #4a90e2;
        border-color: #4a90e2;
        outline: none;
      }
    `};

  ${props =>
    props.variant === FormControlVariant.big &&
    css`
      height: 65px;
      font-size: 18px;
      border-radius: 8px;

      .react-select__value-container {
        padding: 0.375rem 0.75rem;
      }
    `}

  .${CLASS_NAME_PREFIX}__control {
    min-height: 36px;
    height: 36px;
    border: 1px solid #ced4da;
    font-family: Ubuntu;
    box-shadow: none;

    :hover,
    :focus-within {
      box-shadow: 0 0 5px #4a90e2;
      border-color: #4a90e2;
    }

    &--menu-is-open {
      border: 1px solid #4a90e2;
    }

    ${props =>
      props.variant === FormControlVariant.big &&
      css`
        height: 65px;
        min-height: 65px;
        border-radius: 8px;
        border: 1px solid
          ${
            props.appearance === SelectAppearance.Secondary
              ? '#4a90e2'
              : '#979797'
          };
      `}
    &--is-focussed {
      box-shadow: 0 0 5px #4a90e2;
      border-color: #4a90e2;
      color: #495057;
      outline: none;

      ${props =>
        props.error &&
        css`
          border-color: #d0021b;
          box-shadow: 0 0 5px #d0021b;
          :focus,
          :hover,
          :focus:hover {
            border-color: #d0021b;
            box-shadow: 0 0 5px #d0021b;
          }
        `}
    }

    &--is-disabled {
      background-color: #f5f5f5;
      color: #495057;
      cursor: not-allowed;
    }

    ${props =>
      props.error &&
      css`
        border-color: #d0021b;
        :hover {
          border-color: #d0021b;
        }
      `}
  }
  .${CLASS_NAME_PREFIX}__value-container {
    padding: 2px 0 2px 8px;

    .${CLASS_NAME_PREFIX}__single-value {
      color: #4a4a4a;
    }
  }

  .${CLASS_NAME_PREFIX}__indicator {
    padding: 5px 4px 5px 2px;
    cursor: pointer;
    color: #4a4a4a;
    display: flex;
    align-items: center;
  }

  ${props =>
    props.longList &&
    css`
      .${CLASS_NAME_PREFIX}__option {
        padding: 12px 0.75rem;
      }
    `}

  ${props =>
    props.noSeparator &&
    css`
      .${CLASS_NAME_PREFIX}__indicator-separator {
        display: none;
      }
    `}

  .${CLASS_NAME_PREFIX}__value-container {
    cursor: pointer;
    font-family: Ubuntu;
  }
  .${CLASS_NAME_PREFIX}__value-container > input {
    height: unset !important;
  }
`;

const addCypressDataAttributes =
  <P extends { innerProps: unknown; value?: string }>(
    Component: ComponentType<P>,
    dataCy: string
  ) =>
  (props: P) => {
    return (
      <Component
        {...props}
        innerProps={Object.assign({}, props.innerProps, {
          'data-cy': dataCy,
          'data-value': props.value,
        })}
      />
    );
  };

const addCustomProps =
  <P extends { innerProps: unknown }>(
    Component: ComponentType<P>,
    customProps: Partial<SelectBoxProps>
  ) =>
  (props: P) => {
    return <Component {...props} {...customProps} />;
  };

const Option = styled(components.Option)<{
  readonly variant?: FormControlVariant;
}>`
  font-family: Ubuntu;
  cursor: pointer !important;
  font-size: 14px !important;
  color: #4a4a4a !important;

  ${({ isSelected }) => isSelected && `color: #ffffff !important;`};
  ${({ variant }) =>
    variant === FormControlVariant.big &&
    css`
      font-size: 18px !important;
    `}
`;

const Menu = styled(components.Menu)`
  margin-bottom: 4px;
`;

const DropdownIndicator = (
  props: DropdownIndicatorProps & { variant?: FormControlVariant }
) => (
  <components.DropdownIndicator {...props}>
    <Icon
      size={
        props.variant === FormControlVariant.big ? IconSize.s24 : IconSize.s20
      }
    >
      <ExternalIcon name="ChevronDown" />
    </Icon>
  </components.DropdownIndicator>
);

const createCustomComponents = (
  prefix: string,
  variant: FormControlVariant = FormControlVariant.default
) => ({
  Option: addCustomProps(addCypressDataAttributes(Option, `${prefix}-option`), {
    variant,
  }),
  Menu: addCypressDataAttributes(Menu, `${prefix}-menu`),
  SelectContainer: addCypressDataAttributes(
    components.SelectContainer,
    `${prefix}`
  ),
  DropdownIndicator: addCustomProps(DropdownIndicator, {
    variant,
  }),
});

export interface SelectBoxProps {
  autoFocus?: boolean;
  autoComplete?: string;
  appearance?: SelectAppearance;
  id: string;
  label?: string;
  name?: string;
  options: SelectValueProps[];
  optional?: boolean;
  optionalMessage?: string;
  error?: string;
  help?: string;
  longList?: boolean;
  noSeparator?: boolean;
  placeholder?: string;
  value?: SelectValueProps;
  variant?: FormControlVariant;
  defaultValue?: SelectValueProps;
  disabled?: boolean;
  noExtraLabel?: boolean;
  isSearchable?: boolean;
  isClearable?: boolean;
  noShadow?: boolean;
  onChange?(value: unknown): void;
  'data-cy'?: string;
  noLabel?: boolean;
  noError?: boolean;
  disablePortal?: boolean;
  maxMenuHeight?: number;
  tooltip?: ComponentType;
}

export const SelectBox = forwardRef(function SelectBox(
  props: SelectBoxProps,
  passedRef: any
): JSX.Element {
  const {
    appearance = SelectAppearance.Primary,
    label,
    error,
    disabled,
    help,
    optional,
    value,
    optionalMessage,
    noExtraLabel,
    noShadow,
    noLabel,
    noError,
    disablePortal,
    maxMenuHeight,
    tooltip,
    ...selectProps
  } = props;
  const dataCy = props['data-cy'] || 'react-select';
  const innerRef = useRef<any>(null);
  const ref = useMergeRefs([passedRef, innerRef]);
  const variant = selectProps.variant;
  const customComponents = useMemo(() => {
    return createCustomComponents(dataCy, variant);
  }, [dataCy, variant]);

  const TooltipWrapper = tooltip ?? Fragment;

  const select = (
    <StyledSelect
      appearance={appearance}
      isSearchable
      noShadow={noShadow}
      classNamePrefix={CLASS_NAME_PREFIX}
      {...selectProps}
      value={value}
      aria-label={label}
      isDisabled={disabled}
      error={error}
      ref={ref}
      components={customComponents as any}
      menuPortalTarget={!disablePortal ? document.body : undefined}
      maxMenuHeight={maxMenuHeight}
      onMenuOpen={() => {
        setTimeout(() => lock(innerRef.current.menuListRef), 1);
      }}
      onMenuClose={() => {
        unlock(innerRef.current.menuListRef);
      }}
    />
  );

  const selectWithLabels = (
    <>
      {!noLabel && (
        <StyledLabel variant={selectProps.variant}>
          {label}
          {optional && (
            <StyledOptionalMessage>{optionalMessage}</StyledOptionalMessage>
          )}
        </StyledLabel>
      )}
      {help && <StyledHelp>{help}</StyledHelp>}
      <TooltipWrapper>{select}</TooltipWrapper>
      {!noError && <StyledError>{error}&nbsp;</StyledError>}
    </>
  );

  return noExtraLabel ? select : selectWithLabels;
});

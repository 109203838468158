import {
  CommandPalettePortalTarget,
  ToastContainerStyles,
} from '@yarmill/components';
import { useCurrentUserStore, useRootStore } from '@yarmill/utils';
import { Suspense, lazy } from 'react';
import { Slide, ToastContainer } from 'react-toastify';
import styled from 'styled-components';
import { Announcement } from '../announcement/announcement';
import { useIsLoggedIn } from '../auth/hooks';
import { Cgm } from '../cgm';
import { CommandPalette } from '../command-palette';
import { ImagePreview } from '../image-preview';
import { LayerManager } from '../layer-manager';
import { Offline } from '../offline/offline';
import { VideoPlayer } from '../video-player';
import { VideoSelector } from '../videoselector';
import { Workout } from '../workouts';
import { Yollanda } from '../yollanda';
import { Toaster } from './toaster';

const DebugPalette = lazy(() => import('../debug-mode'));
const StyledToastContainer = styled(ToastContainer)`
  ${ToastContainerStyles};
`;
export function ModalRouter(): JSX.Element | null {
  const loggedIn = useIsLoggedIn();
  const rootStore = useRootStore();
  const currentUser = useCurrentUserStore();

  if (!loggedIn) {
    return (
      <>
        <LayerManager />
        <Offline />
        <StyledToastContainer transition={Slide} draggablePercent={50} />
        <Toaster />
      </>
    );
  }

  return (
    <>
      <VideoPlayer />
      <VideoSelector />
      <ImagePreview />
      <Announcement />
      <Workout />
      <Cgm />
      <LayerManager />
      <StyledToastContainer transition={Slide} draggablePercent={50} />
      <Toaster />
      <Offline />
      {rootStore.isReady && (
        <>
          <CommandPalette />
          <Yollanda />
          {(currentUser.isAllowedTo('app.impersonate') ||
            window['ytd_instances']) && (
            <Suspense>
              <DebugPalette />
            </Suspense>
          )}
          <CommandPalettePortalTarget id="ytd-cmd-palette-portal-target" />
        </>
      )}
    </>
  );
}

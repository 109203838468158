import { dashboardTypes } from '../analytics/types';
import { ForgottenPassword } from '../auth/forgotten-password';
import { Login } from '../auth/login';
import { ExpiredToken } from '../expired-token/expired-token';
import { Connect } from '../external-services/connect';
import { AddGroup } from '../groups/add-group';
import { Groups } from '../groups/groups';
import { Analytics } from '../modules/analytics';
import { Attendance } from '../modules/attendance';
import { Evidence } from '../modules/evidence';
import { FilesOverview } from '../modules/files-overview';
import { Metodej } from '../modules/metodej';
import { Okrs } from '../modules/okrs';
import { Plan } from '../modules/plan';
import { Planner } from '../modules/planner';
import { Reality } from '../modules/reality';
import { Reporting } from '../modules/reporting';
import { SeasonEvaluation } from '../modules/season-evaluation';
import { NoGroup } from '../no-group/no-group';
import { NotFound } from '../not-found/not-found';
import { Profile } from '../profile/profile';
import { ResetPassword } from '../profile/reset-password';
import { RegistrationProcess } from '../register/registration-process';
import { TermsAndConditions } from '../terms/terms-and-conditions';
import { Users } from '../users/users';
import { BaseRoute } from './base-route';
import { RouteConfiguration } from './types';

export const routes: RouteConfiguration[] = [
  {
    path: '/',
    public: true,
    exact: true,
    component: BaseRoute,
    permissionScope: null,
  },
  { path: '/login', public: true, component: Login, permissionScope: null },
  {
    path: '/forgottenPassword',
    public: true,
    component: ForgottenPassword,
    permissionScope: null,
  },
  {
    path: '/register',
    public: true,
    component: RegistrationProcess,
    permissionScope: null,
  },
  {
    path: '/tokenExpired',
    public: true,
    component: ExpiredToken,
    permissionScope: null,
  },
  {
    path: '/resetPassword',
    public: true,
    component: ResetPassword,
    permissionScope: null,
  },
  {
    path: '/terms-and-conditions',
    public: true,
    component: TermsAndConditions,
    permissionScope: null,
  },
  {
    path: '/not-found',
    public: true,
    component: NotFound,
    permissionScope: null,
  },

  // Private Routes
  {
    component: Reality,
    path: '/:diaryType(reality)/:viewType(week|season|goals|seasonGoals)',
    permissionScope: ['reality'],
  },
  {
    component: Plan,
    path: '/:diaryType(plan)/:viewType(week|season|goals|seasonGoals)',
    permissionScope: ['plan'],
  },
  {
    component: Analytics,
    path: `/:diaryType(analytics)/:dashboardType(${dashboardTypes.join('|')})`,
    permissionScope: 'analytics',
  },
  {
    component: Reporting,
    path: `/reporting/:dashboardType`,
    permissionScope: 'reporting',
  },
  {
    component: Attendance,
    path: `/attendance/:viewType(week|season)`,
    permissionScope: 'attendance',
  },
  {
    component: Evidence,
    path: '/evidence/:moduleKey',
    permissionScope: 'evidence',
  },
  {
    component: Groups,
    path: '/settings/groups/:groupId?',
    permissionScope: 'settings.groups',
  },
  {
    component: AddGroup,
    path: '/settings/groups/createNew',
    permissionScope: 'settings.groups',
  },
  {
    component: Profile,
    path: '/settings/users/:userId',
    permissionScope: 'settings.users.detail',
  },
  {
    component: Users,
    path: '/settings/users',
    permissionScope: 'settings.users',
  },
  {
    component: Profile,
    path: '/settings/personal',
    permissionScope: 'settings.personal',
  },
  {
    component: FilesOverview,
    path: '/filesOverview',
    permissionScope: 'filesOverview',
  },
  {
    component: SeasonEvaluation,
    path: '/seasonEvaluation',
    permissionScope: 'seasonEvaluation',
  },

  {
    component: Okrs,
    path: '/okr',
    permissionScope: 'okr',
    exact: false,
  },

  {
    component: Planner,
    path: '/planner',
    permissionScope: 'planner',
    exact: false,
  },
  {
    path: '/connect',
    component: Connect,
    permissionScope: 'app.connect',
  },
  {
    path: '/metodej',
    component: Metodej,
    permissionScope: 'metodej',
    exact: false,
  },
  { path: '/noGroup', component: NoGroup, permissionScope: null },
];

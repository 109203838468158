import * as React from 'react';
import styled, { css } from 'styled-components';

const FOCUS_COLOR = 'rgba(74,144,226,0.5)';

export interface HeadCellProps {
  hasCategory?: boolean;
  cyclesView?: boolean;
  firstChild?: boolean;
}

export const ActivityHeadCellWrapper = styled.div`
  line-height: 15px;
`;
export const HeadCell = styled.div<HeadCellProps>`
  text-align: center;
  position: sticky;
  top: ${props =>
    props.hasCategory ? (props.cyclesView ? '48px' : '30px') : 0};
  background: #fff;
  border: 1px solid #fff;
  z-index: 1;
  padding: 5px;

  &:first-child {
    left: 0;
    z-index: 3;
  }

  ${props =>
    props.firstChild &&
    css`
      left: 0;
      z-index: 3;
    `}
`;

export interface HeadCategoryCellProps {
  span: number;
  column: number;
}

export const HeadCategoryCell = styled(HeadCell)<HeadCategoryCellProps>`
  top: 0;
  grid-column: ${props => `${props.column} / span ${props.span}`};
  line-height: 18px;
`;

export const ActivityTitleCell = styled.div`
  white-space: nowrap;
  position: sticky;
  left: 0;
  background: #fff;
  padding-right: 20px;
  border: 1px solid #ffffff;
  display: flex;
  align-items: center;
  z-index: 2;
  color: #4a4a4a;
`;

const BaseCell = styled.div`
  border-style: solid;
  border-width: 1px 0 0 1px;
  border-color: #e5e5e5 transparent transparent #e5e5e5;

  &:hover {
    :before,
    :after {
      content: '';
      position: absolute;
      top: 0;
      width: 1px;
      height: 100%;
      background: ${FOCUS_COLOR};
    }
    :before {
      transform: translateX(-1px);
    }
  }
`;

export const ActivityRowWrapper = styled.div`
  display: contents;

  &:last-child > ${BaseCell} {
    border-bottom: 1px solid #e5e5e5;
  }
`;

export const SummaryCell = styled(BaseCell)`
  border-right-color: #e5e5e5;
  border-right-width: 1px;
  &:hover {
    :after {
      transform: translateX(0);
    }
  }
`;
export const ContentCell = styled(BaseCell)`
  &:focus-within {
    border-right-color: #e5e5e5;
  }
`;

export const HoverWrapper = styled.div`
  display: inline-block;
  width: 100%;

  &:hover {
    :before,
    :after {
      position: absolute;
      content: '';
      background-color: ${FOCUS_COLOR};
      left: 0;
      width: 100%;
      height: 1px;
    }
    :before {
      transform: translateY(-1px);
    }
  }
`;

interface TableProps {
  columnsCount: number;
}

const Table = styled.div<TableProps>`
  display: inline-grid;
  position: relative;
  grid-template-columns: auto repeat(${props => props.columnsCount - 1}, 1fr);
  margin-right: 12px;
`;

export type ActivityTableProps = React.PropsWithChildren<{
  columnsCount: number;
  className?: string;
}>;

export function ActivitiesTable(props: ActivityTableProps): JSX.Element {
  const { columnsCount, children, className } = props;
  return (
    <Table columnsCount={columnsCount} className={className}>
      {children}
    </Table>
  );
}

const focus = css`
  box-shadow: 0 0 5px #4a90e2;
  outline: none;
  border-color: #4a90e2;
  position: relative;
`;

const selectedStyle = css`
  ${focus};
  background-color: rgba(74, 144, 226, 0.1);
`;
export const ActivityInput = styled.input<{ isSelected?: boolean }>`
  display: block;
  border: none;
  width: 100%;
  height: 25px;
  padding: 5px;
  margin: 0;
  outline: 0;
  box-shadow: none;
  background: transparent;
  border-radius: 0;
  font-size: 11px;
  line-height: 16px;
  font-family: 'Roboto Mono', 'Apple Color Emoji', 'Noto Color Emoji', serif;
  text-align: right;
  -moz-appearance: textfield; /*For FireFox*/
  min-width: calc(6ch + 10px);
  color: #4a4a4a;

  :focus {
    ${focus};
  }

  ${({ isSelected }) => isSelected && selectedStyle}

  :disabled {
    background-color: #f5f5f5;
    cursor: not-allowed;

    @media print {
      & {
        background-color: #fff;
      }
    }
  }
`;

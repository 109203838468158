import {
  ContentBox,
  PageMainContent,
  PageScrollContainer,
  Text,
  TextSize,
} from '@yarmill/components';
import {
  useCurrentUserStore,
  useHelmetTitle,
  useUsersStore,
} from '@yarmill/utils';
import { observer } from 'mobx-react-lite';
import { ReactElement } from 'react';
import { Helmet } from 'react-helmet';
import { FormattedMessage, useIntl } from 'react-intl';
import { useRouteMatch } from 'react-router-dom';
import styled from 'styled-components';
import { Page } from '../page/page';
import { Sidebar } from '../settings/sidebar';
import { ProfileHeader } from './header';
import { ProfileDetail } from './profile-detail';

export interface ProfileRouteProps {
  userId?: string;
}

const ProfileWrapper = styled.div`
  max-width: 860px;
  width: 100%;
  margin: 0 auto;

  & > div {
    margin-bottom: 20px;
  }
`;

export const Profile = observer(function Profile(): ReactElement | null {
  const { params } = useRouteMatch<ProfileRouteProps>();
  const currentUser = useCurrentUserStore();
  const userId = params.userId ? Number(params.userId) : currentUser.id;
  const usersStore = useUsersStore();
  const user =
    usersStore.getUserById(userId) ?? usersStore.getDeactivatedUserById(userId);
  const intl = useIntl();

  const helmetTitle = useHelmetTitle([
    intl.formatMessage({
      id: `header.navigation.settings`,
    }),
  ]);

  return (
    <>
      <Helmet>
        <title>{helmetTitle}</title>
      </Helmet>
      <Page>
        <Sidebar
          activeView={user?.id !== currentUser.id ? 'users' : 'personal'}
        />
        <PageMainContent>
          <PageScrollContainer>
            {!currentUser.data || !user ? (
              <ContentBox>
                <Text size={TextSize.s14}>
                  <FormattedMessage id="settings.users.invalidUser" />
                </Text>
              </ContentBox>
            ) : (
              <ProfileWrapper>
                <ProfileHeader user={user} />
                <ProfileDetail user={user} />
              </ProfileWrapper>
            )}
          </PageScrollContainer>
        </PageMainContent>
      </Page>
    </>
  );
});

import { ContentBox, IconSize, Spinner, styled } from '@yarmill/components';
import { observer } from 'mobx-react-lite';
import { useEffect, useState } from 'react';
import { FormattedMessage } from 'react-intl';

export interface StyledImageProps {
  readonly loaded: boolean;
}
export const StyledImage = styled.img<StyledImageProps>`
  opacity: ${props => (props.loaded ? 1 : 0)};
  position: ${props => (props.loaded ? 'relative' : 'absolute')};
  top: 0;
  left: 0;
  max-width: 90vw;
  max-height: calc(100vh - 150px - var(--vh-offset, 0px));
`;

const ImageWrapper = styled.div`
  max-height: calc(100vh - 150px - var(--vh-offset, 0px));
`;

export interface ImageProps {
  src: string | Promise<string>;
  isImageLoaded: boolean;
  isValidSrc: boolean;
  setIsImageLoaded: (val: boolean) => void;
  setIsValidSrc: (val: boolean) => void;
}

export const Image = observer(function Image(props: ImageProps): JSX.Element {
  const { src, setIsImageLoaded, setIsValidSrc, isImageLoaded, isValidSrc } =
    props;
  const [dimensions, setDimensions] = useState<
    | {
        width: number;
        height: number;
      }
    | undefined
  >(undefined);

  useEffect(() => {
    setIsValidSrc(!!src);
    setIsImageLoaded(false);
  }, [src, setIsValidSrc, setIsImageLoaded]);

  useEffect(() => {
    if (typeof src === 'string' && src.startsWith('blob:')) {
      setIsImageLoaded(true);
    }
  }, [src, setIsImageLoaded]);

  return (
    <>
      {isValidSrc && typeof src === 'string' && (
        <ImageWrapper>
          <StyledImage
            loaded={isImageLoaded}
            width={dimensions?.width}
            height={dimensions?.height}
            src={src}
            alt={src}
            onLoad={e => {
              setIsImageLoaded(true);
              if (e.target instanceof HTMLImageElement) {
                const { width, height } = e.target;
                setDimensions({ width, height });
              }
            }}
            onError={() => setIsValidSrc(false)}
            data-cy="image-preview"
          />
        </ImageWrapper>
      )}
      {!isValidSrc && (
        <ContentBox>
          <FormattedMessage id="imagePreview.invalidImage" />
        </ContentBox>
      )}
      {isValidSrc && !isImageLoaded && <Spinner size={IconSize.s48} />}
    </>
  );
});

import { Page } from '../page/page';
import { LazyModule } from './lazy-module';

export function FilesOverview() {
  return (
    <Page>
      <LazyModule moduleCode="filesOverview" />
    </Page>
  );
}

import { Page } from '../page/page';
import { LazyModule } from './lazy-module';

export function SeasonEvaluation() {
  return (
    <Page>
      <LazyModule moduleCode="seasonEvaluation" />
    </Page>
  );
}

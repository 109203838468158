import {
  ContentBox,
  FormattedHTMLMessage,
  PageMainContent,
  PageScrollContainer,
  Text,
  TextSize,
} from '@yarmill/components';
import { useConfig, useGroupsStore, useHelmetTitle } from '@yarmill/utils';
import { observer } from 'mobx-react-lite';
import { Fragment, ReactElement } from 'react';
import { Helmet } from 'react-helmet';
import { FormattedMessage, useIntl } from 'react-intl';
import { useRouteMatch } from 'react-router-dom';
import { Page } from '../page/page';
import { Sidebar } from '../settings/sidebar';
import { GroupsHeader } from './header';
import { SecondarySidebar } from './secondary-sidebar';
import { GroupsToolbar } from './toolsbar';
import { UsersInGroupList } from './users-in-group-list';

export interface GroupRouteProps {
  groupId?: string;
}

export const Groups = observer(function Groups(): ReactElement {
  const { params } = useRouteMatch<GroupRouteProps>();
  const groupId = params.groupId ? Number(params.groupId) : undefined;
  const adminName = useConfig('adminName');
  const adminEmail = useConfig('adminEmail');
  const noGroups = useGroupsStore().sortedGroups.length === 0;
  const intl = useIntl();

  const helmetTitle = useHelmetTitle([
    intl.formatMessage({
      id: `header.navigation.settings`,
    }),
  ]);

  return (
    <>
      <Helmet>
        <title>{helmetTitle}</title>
      </Helmet>
      <Page doubleSidebar>
        <Sidebar activeView="groups" />
        <SecondarySidebar currentGroupId={groupId} />
        <PageMainContent>
          <PageScrollContainer>
            {groupId !== undefined && <GroupsHeader groupId={groupId} />}
            <ContentBox>
              {groupId === undefined ? (
                noGroups ? (
                  <Text size={TextSize.s14}>
                    <FormattedHTMLMessage
                      id="noGroup.noGroupMessage"
                      values={{ adminName, adminEmail }}
                    />
                  </Text>
                ) : (
                  <Text size={TextSize.s14}>
                    <FormattedMessage id="settings.groups.selectGroup" />
                  </Text>
                )
              ) : (
                <Fragment>
                  <GroupsToolbar groupId={groupId} />
                  <UsersInGroupList groupId={groupId} key={groupId} />
                </Fragment>
              )}
            </ContentBox>
          </PageScrollContainer>
        </PageMainContent>
      </Page>
    </>
  );
});

import {
  ATHLETE_SEARCH_PARAM,
  GROUP_SEARCH_PARAM,
  ROUTE_DATE_FORMAT,
  WEEK_SEARCH_PARAM,
} from '@yarmill/const';
import { RootStore } from '@yarmill/types';
import { loadPersistedGroupId } from '@yarmill/utils';
import { Location } from 'history';
import moment from 'moment';

export function getDefaultMainRoute(
  rootStore: RootStore,
  location: Location
): string {
  const currentUser = rootStore.currentUserStore;
  const searchParams = new URLSearchParams();
  const isAthlete = currentUser && currentUser.isAthlete;
  const isCoachOrAdmin = currentUser && !currentUser.isAthlete;

  if (!rootStore.isReady || !rootStore.modulesRegistryService.isReady) {
    return window.location.pathname;
  }

  const links = rootStore.modulesRegistryService.enabledModules.flatMap(
    module => module.navigationLinks
  );
  let selectedLink = links[0];

  if (isAthlete) {
    searchParams.set(ATHLETE_SEARCH_PARAM, String(currentUser.id));
  }

  if (isCoachOrAdmin) {
    const planLink = links.find(l => l.moduleCode === 'plan');
    if (planLink) {
      selectedLink = planLink;
    }
  }

  while (selectedLink?.children?.length) {
    selectedLink = selectedLink.children[0];
  }

  const currentDay = moment().format(ROUTE_DATE_FORMAT);

  const groups = rootStore.groupsStore.sortedGroups.filter(group =>
    currentUser.hasPermissionToGroup(group.id)
  );

  if (groups.length === 0 && isCoachOrAdmin) {
    return '/noGroup';
  }

  let groupId = loadPersistedGroupId(rootStore.groupsStore, currentUser);

  if (!groupId) {
    groupId = groups[0]?.id;
  }

  if (!groupId && isCoachOrAdmin) {
    return '/noGroup';
  }

  if (groupId) {
    searchParams.set(GROUP_SEARCH_PARAM, String(groupId));
  }

  searchParams.set(WEEK_SEARCH_PARAM, currentDay);

  if (!selectedLink?.createClickHandler) {
    return '/not-found';
  }

  return selectedLink.createClickHandler(searchParams.toString())(location);
}

export const REDIRECT_ACTION_PARAM = 'ra';

import { NavItem } from '../nav-item';

import { useIntl } from 'react-intl';
import { LinkCallback } from '../../routes/types';

function profileLinkCallback(): string {
  return `/settings/personal`;
}

export function useProfileLink(): LinkCallback {
  return profileLinkCallback;
}

function isLinkActive(pathname: string): boolean {
  return pathname.includes('/settings/');
}

export function SettingsLink(): JSX.Element {
  const link = useProfileLink();
  const intl = useIntl();

  return (
    <NavItem
      to={link}
      moduleKey="settings"
      icon="Adjustments"
      linkText={intl.formatMessage({ id: `header.navigation.settings` })}
      isLinkActive={isLinkActive}
    />
  );
}

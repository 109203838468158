import { ContentBox } from '../content-box';
import { Text } from '../text';
import { css, styled } from '../theme-provider';
import { ReportTitlePosition, VerticalAlignment } from './types';

export const ReportsContainer = styled.div`
  display: grid;
  grid-template-columns: 100%;
  grid-template-rows: auto;
  row-gap: 40px;
  max-width: 960px;
  width: 100%;
  margin: 0 auto;
  position: relative;
  padding-bottom: 16px;

  @media print {
    page-break-before: avoid;
    max-width: unset;
    display: block;
    padding-bottom: 0;

    & > * {
      margin-bottom: 40px;

      &:last-child {
        margin-bottom: 0;
      }
    }
  }

  .headline-1 {
    font-family: "Ubuntu", "Apple Color Emoji", "Noto Color Emoji", sans-serif;
    font-size: 16px;
    font-weight: bold;
  }

  .headline-2 {
    font-family: "Ubuntu", "Apple Color Emoji", "Noto Color Emoji", sans-serif;
    font-size: 13px;
    font-weight: bold;
  }
`;

export const ReportTitleLayout = styled.div<{
  readonly position: ReportTitlePosition;
}>`
  display: grid;
  grid-template-columns: 100%;
  grid-template-rows: auto;
  row-gap: 10px;

  ${({ position }) =>
    position === 'inside' &&
    css`
      margin-top: 20px;
      margin-bottom: 20px;
    `};

  @media print {
    page-break-after: avoid;
    margin-bottom: 20px;
  }
`;

export const ReportContainer = styled.div<{
  readonly sectionArea?: string;
  readonly hasTwoRows?: boolean;
  readonly sectionVerticalAlignment?: VerticalAlignment;
  readonly useSubgrid?: boolean;
}>`
  display: grid;
  grid-template-columns: auto;
  grid-template-rows: ${({ hasTwoRows }) => (hasTwoRows ? 'auto 1fr' : '1fr')};
  row-gap: 20px;
  ${({ sectionArea }) => sectionArea && `grid-area: ${sectionArea}`};
  ${({ sectionVerticalAlignment }) =>
    sectionVerticalAlignment && `align-self: ${sectionVerticalAlignment}`};
  position: relative;
  width: 100%;
  border-radius: 8px;

  @media (min-width: 768px) {
    grid-template-rows: ${({ hasTwoRows, useSubgrid }) =>
      useSubgrid ? 'subgrid' : hasTwoRows ? 'auto 1fr' : '1fr'};
  }

  @media print {
    display: block;
    page-break-inside: avoid;
  }
`;

export const ReportDescription = styled(Text)`
  color: #9b9b9b;
`;

export const ReportingContentBox = styled(ContentBox)`
  overflow: hidden;
`;

export const ReportBackgroundProvider = styled.div<{
  readonly aspectRatio?: number;
  readonly backgroundImage?: string;
}>`
  ${({ aspectRatio }) => aspectRatio && `aspect-ratio: ${aspectRatio}`};
  ${({ backgroundImage }) =>
    backgroundImage &&
    css`
      background-image: url(${backgroundImage});
      background-repeat: no-repeat;
      background-size: contain;
      background-position: center center;
    `};
`;

import {
  ContentBox,
  ContentBoxHead,
  FormattedHTMLMessage,
  IntegratorRoot,
  Text,
  TextTag,
  ThemeProvider,
} from '@yarmill/components';
import { useRootStore } from '@yarmill/utils';
import { useEffect } from 'react';
import { FormattedMessage, IntlProvider } from 'react-intl';
import styled from 'styled-components';

const DisabledInstanceWrapper = styled.div`
  display: flex;
  justify-content: center;
`;

const CenteredContentBox = styled(ContentBox)`
  max-width: 720px;
  white-space: pre-wrap;
`;

export function DisabledInstance(): JSX.Element {
  const rootStore = useRootStore();
  const intlStore = rootStore.intlStore;

  useEffect(() => {
    if (intlStore.version === 'local') {
      void intlStore.loadTranslations(intlStore.locale);
    }
  }, [intlStore]);

  return (
    <ThemeProvider theme="legacy">
      <IntlProvider locale={intlStore.locale} messages={intlStore.messages}>
        <IntegratorRoot>
          <DisabledInstanceWrapper>
            <CenteredContentBox>
              <ContentBoxHead>
                <Text tag={TextTag.span}>
                  <FormattedMessage id="disabledInstance.header" />
                </Text>
              </ContentBoxHead>
              <Text tag={TextTag.p}>
                <FormattedHTMLMessage id="disabledInstance.text" />
              </Text>
            </CenteredContentBox>
          </DisabledInstanceWrapper>
        </IntegratorRoot>
      </IntlProvider>
    </ThemeProvider>
  );
}

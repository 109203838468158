import { ModulesStore } from '@yarmill/types';
import { useRootStore } from '@yarmill/utils';
import { observer } from 'mobx-react-lite';

interface LazyModuleProps {
  readonly moduleCode: keyof ModulesStore;
  readonly waitForRootStore?: boolean;
}

export const LazyModule = observer(function LazyModule({
  moduleCode,
  waitForRootStore,
}: LazyModuleProps) {
  const rootStore = useRootStore();
  const modulesRegistryService = rootStore.modulesRegistryService;
  const definition = modulesRegistryService.enabledModules.find(
    module => module.moduleCode === moduleCode
  );
  const Module = definition?.component;
  const isReady = !waitForRootStore || (waitForRootStore && rootStore.isReady);

  return Module && isReady ? <Module /> : null;
});
